const global = {

    FORUM_TOP_COLUMN: {
        CASE: {
            id: '56fb4ab0b2444224af8b3972412719f7',
            code: 'case',
            name: '案例分析',
        },  //普通
        DOC: {
            id: '21eddf78e14f4dca88af19236e2c4771',
            code: 'doc',
            name: '资料下载'
        }
    },

};

export default global;
