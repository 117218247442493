<template>
  <div>
    <Header></Header>
    <Column></Column>

    <div class="layui-container">
      <div class="layui-row layui-col-space15">
        <div class="layui-col-md8 content detail">
          <div class="fly-panel detail-box">
            <h1>{{ comment.title }}</h1>
            <div class="fly-detail-info">
              <!-- <span class="layui-badge">审核中</span> -->
              <span class="layui-badge layui-bg-green fly-detail-column">{{ comment.column.name }}</span>

              <span class="layui-badge"
                    style="background-color: #999; margin-left: 2px;">{{ 1 == comment.finish ? '已结' : '未结' }}</span>

              <span class="layui-badge layui-bg-black" style="margin-left: 2px;" v-if="comment.sticky">置顶</span>
              <span class="layui-badge layui-bg-red" style="margin-left: 2px;" v-if="comment.essence">精帖</span>

              <div class="fly-admin-box" data-id="123">
                <span class="layui-btn layui-btn-xs jie-admin" style="margin-left: 2px;" type="del"
                      @click="deleteComment(comment.id)" v-if="user.id = comment.publish_user.id">删除</span>

                <span class="layui-btn layui-btn-xs jie-admin" style="margin-left: 2px;" type="set" field="stick"
                      @click="stickyComment(comment.id, 1)" v-if="user.role.code == 'admin'  && 1 != comment.sticky"
                      rank="1">置顶</span>
                <span class="layui-btn layui-btn-xs jie-admin" type="set" field="stick"
                      v-if="user.role.code == 'admin' && 1 == comment.sticky" rank="0"
                      @click="stickyComment(comment.id, 0)" style="background-color:#ccc;margin-left: 2px;">取消置顶</span>

                <span class="layui-btn layui-btn-xs jie-admin" style="margin-left: 2px;" type="set" field="status"
                      @click="essenceComment(comment.id, 1)" v-if="user.role.code == 'admin' && 1 != comment.essence"
                      rank="1">加精</span>
                <span class="layui-btn layui-btn-xs jie-admin" type="set" field="status"
                      @click="essenceComment(comment.id, 0)" v-if="user.role.code == 'admin' && 1 == comment.essence"
                      rank="0" style="background-color:#ccc;margin-left: 2px;">取消加精</span>
              </div>
              <span class="fly-list-nums">
            <a href="#comment"><i class="iconfont" title="回答">&#xe60c;</i> {{ comment.comment_num || 0 }}</a>
            <i class="iconfont" title="人气">&#xe60b;</i> {{ comment.visit_num || 0 }}
          </span>
            </div>
            <div class="detail-about">
              <a class="fly-avatar" href="../user/home">
                <img v-if="comment.publish_user.image" :src="comment.publish_user.image.url"
                     :alt="comment.publish_user.name">
              </a>
              <div class="fly-detail-user">
                <a href="javascript:;" class="fly-link">
                  <cite>{{ comment.publish_user.name }}</cite>
                  <!--                  <i class="iconfont icon-renzheng" title="认证信息：分析师"></i>-->
                  <i class="layui-badge fly-badge-vip" style="margin-left: 2px;">{{
                    comment.publish_user.role.name
                    }}</i>
                </a>
                <span>{{ comment.publish_time }}</span>
              </div>
              <div class="detail-hits" id="LAY_jieAdmin" data-id="123">
                <!--                <span style="padding-right: 10px; color: #FF7200">悬赏：60飞吻</span>-->
                <span class="layui-btn layui-btn-xs jie-admin" type="edit"><a href="javascript:;"
                                                                              @click="goEditComment(comment.id)">编辑此贴</a></span>
              </div>
            </div>
            <div class="detail-body photos" v-html="comment.content">
            </div>
          </div>

          <div class="fly-panel detail-box" id="flyReply">
            <fieldset class="layui-elem-field layui-field-title" style="text-align: center;">
              <legend>回帖</legend>
            </fieldset>

            <ul class="jieda" id="jieda">
              <li data-id="111" class="jieda-daan" v-for="comment in replyComments" :key="comment.id">
                <a name="item-1111111111"></a>
                <div class="detail-about detail-about-reply">
                  <a class="fly-avatar" href="">
                    <img v-if="comment.publish_user.image" :src="comment.publish_user.image.url"
                         :alt="comment.publish_user.name">
                  </a>
                  <div class="fly-detail-user">
                    <a href="" class="fly-link">
                      <cite>{{ comment.publish_user.name }}</cite>
                      <!--                      <i class="iconfont icon-renzheng" title="认证信息：XXX"></i>-->
                      <i class="layui-badge fly-badge-vip" style="margin-left: 2px;">{{
                        comment.publish_user.role.name
                        }}</i>
                    </a>

                    <span v-if="user.id == comment.publish_user.id">(楼主)</span>
                    <!--
                    <span style="color:#5FB878">(管理员)</span>
                    <span style="color:#FF9E3F">（社区之光）</span>
                    <span style="color:#999">（该号已被封）</span>
                    -->
                  </div>

                  <div class="detail-hits">
                    <span>{{ comment.publish_time }}</span>
                  </div>
                  <i class="iconfont icon-caina" title="最佳答案" v-if="1 == comment.accept"></i>
                </div>
                <div class="detail-body jieda-body photos">
                  <p>{{ comment.content }}</p>
                </div>
                <div class="jieda-reply">
                  <span class="jieda-zan zanok" type="zan" @click="increaseLike(comment.id)">
                    <i class="iconfont icon-zan"></i>
                    <em>{{ comment.like_num || 0 }}</em>
                  </span>

                  <!--                  <span type="reply">-->
                  <!--                    <i class="iconfont icon-svgmoban53"></i>回复-->
                  <!--                  </span>-->
                  <div class="jieda-admin">
                    <!--                    <span type="edit">编辑</span>-->
                    <span type="del" v-if="user.id == comment.publish_user.id"
                          @click="deleteReply(comment.id)">删除</span>
                    <span class="jieda-accept" type="accept" v-if="user.id == comment.publish_user.id"
                          @click="acceptReply(comment.id)">采纳</span>
                  </div>
                </div>
              </li>

              <!-- 无数据时 -->
              <li v-if="replyComments.length == 0" class="fly-none">消灭零回复</li>
            </ul>

            <div class="layui-form layui-form-pane">
              <form>
                <div class="layui-form-item layui-form-text">
                  <a name="comment"></a>
                  <div class="layui-input-block">
                    <textarea id="L_content" name="content" required lay-verify="required" placeholder="请输入内容"
                              class="layui-textarea fly-editor" style="height: 150px;" v-model="content"></textarea>
                  </div>
                </div>
              </form>
              <div class="layui-form-item">
                <input type="hidden" name="jid" value="123">
                <button class="layui-btn" lay-filter="*" @click="submitReply">提交回复</button>
              </div>
            </div>
          </div>
        </div>
        <div class="layui-col-md4">
          <dl class="fly-panel fly-list-one">
            <dt class="fly-panel-title">热议问题</dt>

            <div v-if="hotComments.length > 0">
              <dd v-for="comment in hotComments" :key="comment.id">
                <a href="javascript:;" @click="goCommentDetail(comment)">{{ comment.title }}</a>
                <span><i class="iconfont icon-pinglun1"></i> {{ comment.comment_num || 0 }}</span>
              </dd>
            </div>

            <!-- 无数据时 -->
            <div v-else class="fly-none">没有相关数据</div>
          </dl>


          <div class="fly-panel">
            <div class="fly-panel-title">
              官方出品
            </div>
            <div class="fly-panel-main">
              <a href="http://tf.ynatd.com" target="_blank" class="fly-zanzhu"
                 time-limit="2017.09.25-2099.01.01"
                 style="background-color: #20222A; background-image: linear-gradient(to right,#20222A,#3E4251);">嘉奇教育ATD多元智能天赋测评系统</a>
            </div>
            <div class="fly-panel-main">
              <a href="http://www.ynatd.com" target="_blank" class="fly-zanzhu"
                 time-limit="2017.09.25-2099.01.01"
                 style="background-color: #009688; background-image: linear-gradient(to right,#009688,#5FB878);">嘉奇教育官网</a>
            </div>
          </div>

          <div class="fly-panel">
            <div class="fly-panel-title">
              官方推荐
            </div>
            <div class="fly-panel-main">
              <a href="https://tf.ynatd.com/img/recharge.7d4bfe13.png" target="_blank" class="fly-zanzhu"
                 time-limit="2017.09.25-2099.01.01"
                 style="background-color: #ff6a00; background-image: linear-gradient(to right,#ff6a00,#f39b5c);">现在缴费立享优惠</a>
            </div>
          </div>

          <div class="fly-panel" style="padding: 20px 0; text-align: center;">
            <img src="@/assets/res/images/weixin.jpg" style="max-width: 100%;" alt="layui">
            <p style="position: relative; color: #666;">微信扫码关注 嘉奇教育 公众号</p>
          </div>

        </div>
      </div>
    </div>

    <Footer></Footer>

  </div>
</template>
<script>

import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Column from "@/components/Column"
import {
  addComment,
  getCommentDetail,
  deleteComment,
  editComment,
  getHotComment,
  getReplyComment,
  increaseVisit,
  increaseLike
} from "@/assets/api/forum";
import {getUserInfo} from "@/assets/api/user";

export default {
  name: 'CaseDetail',
  components: {
    Header,
    Footer,
    Column,
  },

  data() {
    return {
      comment: {
        id: '',
        column: {
          id: '',
          name: ''
        },
        publish_user: {
          id: '',
          name: '',
          role: {
            name: ''
          }
        }
      },
      hotComments: [],
      replyComments: [],
      content: '',
      user: {
        id: '',
        role: {
          code: ''
        }
      }
    }
  },

  watch: {
    $route: {
      handler(val, oldval) {
        this.comment.id = this.$route.query.id
        this.initPage();
      },
    }
  },

  created() {
    this.comment.id = this.$route.query.id
    this.initPage();
  },

  mounted() {
    this.increaseVisit();
  },

  methods: {
    initPage() {
      this.initUserInfo();
      this.initComment();
      this.getHotComments();
      this.getReplyComments();
    },
    increaseVisit() {
      increaseVisit({id: this.comment.id}).then(res => {
        this.getReplyComments();
      })
    },
    increaseLike(id) {
      increaseLike({id: id}).then(res => {
        this.getReplyComments();
      })
    },
    acceptReply(id) {
      let that = this;
      that.$confirm('是否采纳该回复, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        editComment({id: id, accept: 1, finish: 1}).then(res => {
          console.log('res: ', res);
          that.getReplyComments()
        })
      }).catch(() => {
        alert('已取消操作');
      });
    },

    deleteReply(id) {
      let that = this;
      that.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteComment({id: id}).then(res => {
          console.log('res: ', res);
          that.getReplyComments()
        })
      }).catch(() => {
        alert('已取消删除');
      });
    },

    deleteComment(id) {
      let that = this;
      that.$confirm('此操作将永久删除数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteComment({id: id}).then(res => {
          console.log('res: ', res);
          that.$router.push({
            path: '/index'
          })
        })
      }).catch(() => {
        alert('已取消删除');
      });
    },

    stickyComment(id, val) {
      let that = this;
      editComment({id: id, sticky: val}).then(res => {
        that.initComment();
      })
    },
    essenceComment(id, val) {
      let that = this;
      editComment({id: id, essence: val}).then(res => {
        that.initComment();
      })
    },
    initUserInfo() {
      let that = this;
      getUserInfo().then(res => {
        that.user = res.result;
      })
    },
    initComment() {
      let that = this;
      getCommentDetail({id: this.comment.id}).then(res => {
        console.log('comment: ', res.result);
        that.comment = res.result;
      })
    },
    getReplyComments() {
      let that = this;
      getReplyComment({parentId: this.comment.id}).then(res => {
        console.log('hotComments: ', res.result)
        that.replyComments = res.result;
      })
    },
    getHotComments() {
      let that = this;
      getHotComment().then(res => {
        console.log('hotComments: ', res.result)
        that.hotComments = res.result;
      })
    },
    goCommentDetail(comment) {
      this.$router.push({
        path: '/case/detail',
        query: {
          id: comment.id
        }
      })
    },
    goEditComment(id) {
      this.$router.push({
        path: '/case/edit',
        query: {
          id: id
        }
      })
    },
    submitReply() {
      let that = this;
      let data = {
        parent: {
          id: that.comment.id
        },
        content: that.content,
        column: that.comment.column,
      }
      addComment(data).then(res => {
        if (res.result) {
          that.content = ''
          that.getReplyComments()
        }
      })
    }
  }
}


</script>