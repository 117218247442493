<template>
  <div>

    <Header></Header>
    <Column></Column>

    <div class="layui-container">
      <div class="layui-row layui-col-space15">
        <div class="layui-col-md8">
          <div class="fly-panel">
            <div class="fly-panel-title fly-filter">
              <a>置顶</a>
            </div>
            <ul class="fly-list">

              <li v-for="comment in stickyComments"
                  :key="comment.id">
                <a href="user/home.html" class="fly-avatar">
                  <img v-if="comment.publish_user.image" :src="comment.publish_user.image.url"
                       :alt="comment.publish_user.name">
                </a>
                <h2>
                  <a class="layui-badge">{{ comment.column.name }}</a>
                  <a href="javascript:;" @click="goCommentDetail(comment)">{{ comment.title }}</a>
                </h2>
                <div class="fly-list-info">
                  <a href="user/home.html" link>
                    <cite>{{ comment.publish_user.name }}</cite>
                    <!--                    <i class="iconfont icon-renzheng" title="认证信息：XXX"></i>-->
                    <i class="layui-badge fly-badge-vip">{{ comment.publish_user.role.name }}</i>
                  </a>
                  <span>{{ comment.publish_time }}</span>

                  <span class="fly-list-kiss layui-hide-xs" title="关注" v-if="comment.like_num"><i
                      class="iconfont icon-kiss"></i> {{ comment.like_num }}</span>
                  <span class="layui-badge fly-badge-accept layui-hide-xs" v-if="1 == comment.finish">已结</span>
                  <!--                  <span class="layui-badge fly-badge-accept layui-hide-xs">{{ 1 == comment.finish ? '已结' : '未结' }}</span>-->
                  <span class="fly-list-nums">
                    <i class="iconfont icon-pinglun1" title="回答"></i> {{ comment.comment_num || 0 }}
                  </span>
                </div>
                <div class="fly-list-badge">
                  <!--
                  <span class="layui-badge layui-bg-black">置顶</span>
                  -->
                  <span class="layui-badge layui-bg-red" v-if="1 == comment.essence">精华</span>
                </div>
              </li>

            </ul>
          </div>

          <div class="fly-panel" style="margin-bottom: 0;">

            <div class="fly-panel-title fly-filter">
              <a href="" class="layui-this">综合</a>
              <span class="fly-mid"></span>
              <a href="">未结</a>
              <span class="fly-mid"></span>
              <a href="">已结</a>
              <span class="fly-mid"></span>
              <a href="">精华</a>
              <span class="fly-filter-right layui-hide-xs">
                <a href="" class="layui-this">按最新</a>
                <span class="fly-mid"></span>
                <a href="">按热议</a>
              </span>
            </div>

            <ul class="fly-list">

              <li v-for="comment in comments"
                  :key="comment.id">
                <a href="user/home.html" class="fly-avatar">
                  <img v-if="comment.publish_user.image" :src="comment.publish_user.image.url"
                       :alt="comment.publish_user.name">
                </a>
                <h2>
                  <a class="layui-badge">{{ comment.column.name }}</a>
                  <a href="src/views/case/detail">{{ comment.title }}</a>
                </h2>
                <div class="fly-list-info">
                  <a href="user/home.html" link>
                    <cite>{{ comment.publish_user.name }}</cite>
                    <!--                    <i class="iconfont icon-renzheng" title="认证信息：XXX"></i>-->
                    <i class="layui-badge fly-badge-vip">{{ comment.publish_user.role.name }}</i>
                  </a>
                  <span>{{ comment.publish_time }}</span>

                  <span class="fly-list-kiss layui-hide-xs" title="关注" v-if="comment.like_num"><i
                      class="iconfont icon-kiss"></i> {{ comment.like_num }}</span>
                  <span class="layui-badge fly-badge-accept layui-hide-xs" v-if="1 == comment.finish">已结</span>
                  <!--                  <span class="layui-badge fly-badge-accept layui-hide-xs">{{ 1 == comment.finish ? '已结' : '未结' }}</span>-->
                  <span class="fly-list-nums">
                    <i class="iconfont icon-pinglun1" title="回答"></i> {{ comment.comment_num || 0 }}
                  </span>
                </div>
                <div class="fly-list-badge">
                  <span class="layui-badge layui-bg-black" v-if="1 == comment.sticky">置顶</span>
                  <span class="layui-badge layui-bg-red" v-if="1 == comment.essence">精华</span>
                </div>
              </li>

              <!--              <li>-->
              <!--                <a href="user/home.html" class="fly-avatar">-->
              <!--                  <img src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg" alt="贤心">-->
              <!--                </a>-->
              <!--                <h2>-->
              <!--                  <a class="layui-badge">动态</a>-->
              <!--                  <a href="jie/detail">基于 layui 的极简社区页面模版</a>-->
              <!--                </h2>-->
              <!--                <div class="fly-list-info">-->
              <!--                  <a href="user/home.html" link>-->
              <!--                    <cite>贤心</cite>-->
              <!--                    &lt;!&ndash;<i class="iconfont icon-renzheng" title="认证信息：XXX"></i>&ndash;&gt;-->
              <!--                    <i class="layui-badge fly-badge-vip">VIP3</i>-->
              <!--                  </a>-->
              <!--                  <span>刚刚</span>-->

              <!--                  <span class="fly-list-kiss layui-hide-xs" title="悬赏飞吻"><i class="iconfont icon-kiss"></i> 60</span>-->
              <!--                  <span class="layui-badge fly-badge-accept layui-hide-xs">已结</span>-->
              <!--                  <span class="fly-list-nums">-->
              <!--                <i class="iconfont icon-pinglun1" title="回答"></i> 66-->
              <!--              </span>-->
              <!--                </div>-->
              <!--                <div class="fly-list-badge">-->
              <!--                  <span class="layui-badge layui-bg-red">精帖</span>-->
              <!--                </div>-->
              <!--              </li>-->

            </ul>
            <div style="text-align: center">
              <div class="laypage-main">
                <a href="src/views/case/index" class="laypage-next">更多案例</a>
              </div>
            </div>

          </div>
        </div>
        <div class="layui-col-md4">

          <div class="fly-panel">
            <h3 class="fly-panel-title">最新资料</h3>
            <ul class="fly-panel-main fly-list-static" v-if="newestComments.length > 0 ">
              <li v-for="comment in newestComments" :key="comment.id">
                <a href="http://fly.layui.com/jie/4281/" target="_blank">layui 的 GitHub 及 Gitee (码云) 仓库，欢迎Star</a>
              </li>
              <!--              <li>-->
              <!--                <a href="http://fly.layui.com/jie/4281/" target="_blank">layui 的 GitHub 及 Gitee (码云) 仓库，欢迎Star</a>-->
              <!--              </li>-->
            </ul>
            <!-- 无数据时 -->
            <div v-else style="margin-top: 50px; text-align: center; margin-bottom: 20px; height: 70px; color: #999;">
              没有相关数据
            </div>
          </div>


          <!--          <div class="fly-panel fly-signin">-->
          <!--            <div class="fly-panel-title">-->
          <!--              签到-->
          <!--              <i class="fly-mid"></i>-->
          <!--              <a href="javascript:;" class="fly-link" id="LAY_signinHelp">说明</a>-->
          <!--              <i class="fly-mid"></i>-->
          <!--              <a href="javascript:;" class="fly-link" id="LAY_signinTop">活跃榜<span class="layui-badge-dot"></span></a>-->
          <!--              <span class="fly-signin-days">已连续签到<cite>16</cite>天</span>-->
          <!--            </div>-->
          <!--            <div class="fly-panel-main fly-signin-main">-->
          <!--              <button class="layui-btn layui-btn-danger" id="LAY_signin">今日签到</button>-->
          <!--              <span>可获得<cite>5</cite>飞吻</span>-->

          <!--              &lt;!&ndash; 已签到状态 &ndash;&gt;-->
          <!--              &lt;!&ndash;-->
          <!--              <button class="layui-btn layui-btn-disabled">今日已签到</button>-->
          <!--              <span>获得了<cite>20</cite>飞吻</span>-->
          <!--              &ndash;&gt;-->
          <!--            </div>-->
          <!--          </div>-->

          <!--                    <div class="fly-panel fly-rank fly-rank-reply" id="LAY_replyRank">-->
          <!--                      <h3 class="fly-panel-title">回贴周榜</h3>-->
          <!--                      <dl>-->
          <!--                        &lt;!&ndash;<i class="layui-icon fly-loading">&#xe63d;</i>&ndash;&gt;-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                        <dd>-->
          <!--                          <a href="user/home.html">-->
          <!--                            <img-->
          <!--                                src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg"><cite>贤心</cite><i>106次回答</i>-->
          <!--                          </a>-->
          <!--                        </dd>-->
          <!--                      </dl>-->
          <!--                    </div>-->

          <dl class="fly-panel fly-list-one">
            <dt class="fly-panel-title">热议问题</dt>
            <div v-if="hotComments.length > 0">
              <dd v-for="comment in hotComments" :key="comment.id">
                <a href="src/views/case/detail">{{ comment.title }}</a>
                <span><i class="iconfont icon-pinglun1"></i> {{comment.comment_num || 0}}</span>
              </dd>
            </div>

            <!--            <dd>-->
            <!--              <a href="jie/detail">基于 layui 的极简社区页面模版</a>-->
            <!--              <span><i class="iconfont icon-pinglun1"></i> 16</span>-->
            <!--            </dd>-->


            <!-- 无数据时 -->
            <div v-else class="fly-none">没有相关数据</div>

          </dl>

          <div class="fly-panel">
            <div class="fly-panel-title">
              官方出品
            </div>
            <div class="fly-panel-main">
              <a href="http://layim.layui.com/?from=fly" target="_blank" class="fly-zanzhu"
                 time-limit="2021.03.07-2021.03.08"
                 style="background-color: #20222A; background-image: linear-gradient(to right,#20222A,#3E4251);">嘉奇教育ATD多元智能天赋测评系统</a>
            </div>
            <div class="fly-panel-main">
              <a href="http://layim.layui.com/?from=fly" target="_blank" class="fly-zanzhu"
                 time-limit="2021.03.07-2021.03.08"
                 style="background-color: #009688; background-image: linear-gradient(to right,#009688,#5FB878);">嘉奇小学3G课程</a>
            </div>
          </div>

          <div class="fly-panel">
            <div class="fly-panel-title">
              官方推荐
            </div>
            <div class="fly-panel-main">
              <a href="http://layim.layui.com/?from=fly" target="_blank" class="fly-zanzhu"
                 time-limit="2021.03.07-2021.03.08"
                 style="background-color: #ff6a00; background-image: linear-gradient(to right,#ff6a00,#f39b5c);">现在缴费8折起</a>
            </div>
          </div>

          <div class="fly-panel" style="padding: 20px 0; text-align: center;">
            <img src="@/assets/res/images/weixin.jpg" style="max-width: 100%;" alt="layui">
            <p style="position: relative; color: #666;">微信扫码关注 嘉奇教育 公众号</p>
          </div>

          <!--          <div class="fly-panel fly-link">-->
          <!--            <h3 class="fly-panel-title">友情链接</h3>-->
          <!--            <dl class="fly-panel-main">-->
          <!--              <dd><a href="http://www.layui.com/" target="_blank">layui</a></dd>-->
          <!--              <dd><a href="http://layim.layui.com/" target="_blank">WebIM</a></dd>-->
          <!--              <dd><a href="http://layer.layui.com/" target="_blank">layer</a></dd>-->
          <!--              <dd><a href="http://www.layui.com/laydate/" target="_blank">layDate</a></dd>-->
          <!--              <dd><a-->
          <!--                  href="mailto:xianxin@layui-inc.com?subject=%E7%94%B3%E8%AF%B7Fly%E7%A4%BE%E5%8C%BA%E5%8F%8B%E9%93%BE"-->
          <!--                  class="fly-link">申请友链</a></dd>-->
          <!--            </dl>-->
          <!--          </div>-->

        </div>
      </div>
    </div>


    <Footer></Footer>

  </div>
</template>

<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Column from "@/components/Column";
import {getComment, getHotComment, getNewestComment, getStickyComment} from "@/assets/api/forum";

export default {
  name: 'Index',
  components: {
    Header,
    Footer,
    Column,
  },

  data() {
    return {
      stickyComments: [],
      comments: [],
      newestComments: [],
      hotComments: [],
      paging: {}
    }
  },

  created() {
    this.initPage();
  },

  mounted() {
  },

  methods: {
    initPage() {
      this.getStickyComments(); //置顶贴
      this.getComments(); //所有贴
      this.getNewestComments(); //最新贴
      this.getHotComments(); //热议贴
    },
    getStickyComments() {
      let that = this;
      getStickyComment().then(res => {
        console.log('stickyComments: ', res.result)
        that.stickyComments = res.result;
      })
    },
    getComments() {
      let that = this;
      getComment().then(res => {
        console.log('comments: ', res.result)
        that.comments = res.result.data;
      })
    },
    getNewestComments() {
      let that = this;
      getNewestComment().then(res => {
        console.log('newestComments: ', res.result)
        that.newestComments = res.result;
      })
    },
    getHotComments() {
      let that = this;
      getHotComment().then(res => {
        console.log('hotComments: ', res.result)
        that.hotComments = res.result;
      })
    },
    goCommentDetail(comment) {
      this.$router.push({
        path: '/jie/detail',
        query: {
          id: comment.id
        }
      })
    }
  }
}

</script>

<style>

</style>
