<template>
  <div>

    <div class="fly-panel fly-column">
      <div class="layui-container">
        <ul class="layui-clear">
          <li class="layui-hide-xs layui-this"><a href="/">首页</a></li>
          <li :class="cssVar" v-for="column in subColumns" :key="column.id">
            <a href="javascript:;" @click="goSubColumn(column)">{{ column.name }}</a></li>
          <!--          <li><a href="/jie/index">分享<span class="layui-badge-dot"></span></a></li>-->
          <!--          <li><a href="/jie/index">讨论</a></li>-->
          <!--          <li><a href="/jie/index">建议</a></li>-->
          <!--          <li><a href="/jie/index">公告</a></li>-->
          <!--          <li><a href="/jie/index">动态</a></li>-->
          <!--          <li class="layui-hide-xs layui-hide-sm layui-show-md-inline-block"><span class="fly-mid"></span></li>-->

          <!-- 用户登入后显示 -->
          <!--          <li class="layui-hide-xs layui-hide-sm layui-show-md-inline-block"><a href="/user/index">我发表的贴</a></li>-->
          <!--          <li class="layui-hide-xs layui-hide-sm layui-show-md-inline-block"><a-->
          <!--              href="/user/index#collection">我收藏的贴</a></li>-->
        </ul>

        <div class="fly-column-right layui-hide-xs">
          <!--          <span class="fly-search"><i class="layui-icon"></i></span>-->
          <a href="/case/add" class="layui-btn">发表新帖</a>
        </div>
        <!--        <div class="layui-hide-sm layui-show-xs-block"-->
        <!--             style="margin-top: -10px; padding-bottom: 10px; text-align: center;">-->
        <!--          <a href="/jie/add" class="layui-btn">发表新帖</a>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</template>
<script>

import {getSubColumn} from "@/assets/api/forum";
import global from "@/assets/const.define";

export default {
  name: 'Column',
  data() {
    return {
      subColumns: [],
      subColumn: {},
      selectedColumn: '',
    }
  },
  computed: {
    cssVar: function () {
      return {
        'layui-this': this.selectedColumn === this.subColumn.id
      }
    }
  },
  created() {
    this.initPage();
  },
  mounted() {
  },
  methods: {
    initPage() {
      this.initSubColumn();
    },
    initSubColumn() {
      getSubColumn({parentCode: 'case'}).then(res => {
        console.log('column: ', res.result)
        this.subColumns = res.result;
      })
    },
    goSubColumn(column) {
      // this.subColumn = column
      // this.selectedColumn = column.id
      this.$router.push({
        path: '/case/index',
        query: {
          columnId: column.id
        }
      })
    }
  }
}

</script>

<style scoped>

</style>