<template>
  <div>
    <Header></Header>


    <div class="layui-container fly-marginTop">
      <div class="fly-panel" pad20 style="padding-top: 5px;">
        <!--<div class="fly-none">没有权限</div>-->
        <div class="layui-form layui-form-pane">
          <div class="layui-tab layui-tab-brief" lay-filter="user">
            <ul class="layui-tab-title">
              <li class="layui-this">发表新帖<!-- 编辑帖子 --></li>
            </ul>
            <div class="layui-form layui-tab-content" id="LAY_ucm" style="padding: 20px 0;">
              <div class="layui-tab-item layui-show">
                <form action="" method="post">
                  <div class="layui-row layui-col-space15 layui-form-item">
                    <div class="layui-col-md3">
                      <label class="layui-form-label">所在专栏</label>
                      <div class="layui-input-block">
                        <el-select v-model="columnId" placeholder="请选择">
                          <el-option
                              v-for="item in columns"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value">
                          </el-option>
                        </el-select>
                      </div>
                    </div>
                    <div class="layui-col-md9">
                      <label for="L_title" class="layui-form-label">标题</label>
                      <div class="layui-input-block">
                        <input type="text" id="L_title" name="title" required lay-verify="required" autocomplete="off"
                               class="layui-input" v-model="title">
                      </div>
                    </div>
                  </div>
                  <div class="layui-form-item layui-form-text">
                    <div class="layui-input-block">
                      <vue-ueditor-wrap v-model="content" :config="myConfig"></vue-ueditor-wrap>
                    </div>
                  </div>
                  <div class="layui-form-item">
                    <label for="L_vercode" class="layui-form-label">人类验证</label>
                    <div class="layui-input-inline">
                      <input type="text" id="L_vercode" name="vercode" required lay-verify="required" v-model="sumData"
                             placeholder="请回答后面的问题" autocomplete="off" class="layui-input">
                    </div>
                    <div class="layui-form-mid">
                      <span style="color: #c00;">{{addend1}}+{{addend2}}=?</span>
                    </div>
                  </div>

                </form>
                <div class="layui-form-item">
                  <button class="layui-btn" lay-filter="*" lay-submit @click="publish">立即发布</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>

  </div>

</template>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Column from "@/components/Column";
import VueUeditorWrap from 'vue-ueditor-wrap'
import {getSubColumn, addComment} from "@/assets/api/forum";


export default {
  name: 'CaseAdd',
  components: {
    Header,
    Footer,
    Column,
    VueUeditorWrap
  },

  data() {
    return {
      data: '',
      columns: [],
      myConfig: {
        // 如果需要上传功能,找后端小伙伴要服务器接口地址
        serverUrl: process.env.VUE_APP_API_HOST + process.env.VUE_APP_API_BASE_URL + '/attach/ueditor/exec',
        // 你的UEditor资源存放的路径,相对于打包后的index.html
        UEDITOR_HOME_URL: '/ueditor/',
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 工具栏是否可以浮动
        autoFloatEnabled: false,
        // 初始容器高度
        initialFrameHeight: 500,
        // 初始容器宽度
        initialFrameWidth: '100%',
        // 关闭自动保存
        enableAutoSave: true
      },
      title: '',
      columnId: '',
      content: '',
      sumData: '',
      addend1: Math.floor(Math.random() * 10) + 1,
      addend2: Math.floor(Math.random() * 10) + 1,
    }
  },

  created() {

  },

  mounted() {
    this.initPage();
  },

  methods: {
    initPage() {
      this.initForumColumn();
    },
    initForumColumn() {
      let that = this;
      getSubColumn({parentCode: 'case'}).then(res => {
        console.log('subColumn: ', res);
        res.result.forEach((data, i) => {
          that.columns.push({
            value: data.id,
            label: data.name
          })
        })
      })
    },
    publish() {
      let that = this;
      if (!that.columnId) {
        alert('请选择专栏');
        return;
      }
      if (that.sumData == (that.addend1 + that.addend2)) {
        let data = {
          column: {
            id: that.columnId
          },
          title: that.title,
          content: that.content
        }
        addComment(data).then(res => {
          if (res.result) {
            that.$router.push({
              path: '/index'
            })
          }
        })
      } else {
        alert('回答问题错误');
      }
    },
  }
}
</script>
<style>
.el-input__inner {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 38px;
  line-height: 25px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
}
</style>