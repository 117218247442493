import request from '@/utils/request'

const api = {
    userDetail: '/system/user/detail',
    userInfo: '/system/user/info',
}

export default api

export function getUserDetail(parameter) {
    return request({
        url: api.userDetail,
        method: 'get',
        params: parameter
    })
}


export function getUserInfo(parameter) {
    return request({
        url: api.userInfo,
        method: 'get',
        params: parameter
    })
}
