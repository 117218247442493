import request from '@/utils/request'

const api = {
    topColumnList: '/forum/column/top',
    subColumnList: '/forum/column/sub',
    commentAdd: '/forum/comment',
    commentList: '/forum/comment/list',
    commentSticky: '/forum/comment/sticky',
    commentHot: '/forum/comment/hot',
    commentNewest: '/forum/comment/newest',
    commentDetail: '/forum/comment/detail',
    commentReply: '/forum/comment/reply',
    commentEdit: '/forum/comment',
    commentDelete: '/forum/comment',
    commentVisitIncrease: '/forum/comment/increase-visit',
    commentLikeIncrease: '/forum/comment/increase-like',
}

export default api

export function increaseVisit(parameter) {
    return request({
        url: api.commentVisitIncrease,
        method: 'put',
        data: parameter
    })
}

export function increaseLike(parameter) {
    return request({
        url: api.commentLikeIncrease,
        method: 'put',
        data: parameter
    })
}

export function editComment(parameter) {
    return request({
        url: api.commentEdit,
        method: 'put',
        data: parameter
    })
}

export function deleteComment(parameter) {
    return request({
        url: api.commentDelete,
        method: 'delete',
        params: parameter
    })
}

export function getReplyComment(parameter) {
    return request({
        url: api.commentReply,
        method: 'get',
        params: parameter
    })
}

export function getCommentDetail(parameter) {
    return request({
        url: api.commentDetail,
        method: 'get',
        params: parameter
    })
}

export function getNewestComment(parameter) {
    return request({
        url: api.commentNewest,
        method: 'get',
        params: parameter
    })
}

export function getHotComment(parameter) {
    return request({
        url: api.commentHot,
        method: 'get',
        params: parameter
    })
}

export function getStickyComment(parameter) {
    return request({
        url: api.commentSticky,
        method: 'get',
        params: parameter
    })
}

export function getComment(parameter) {
    return request({
        url: api.commentList,
        method: 'get',
        params: parameter
    })
}

export function addComment(parameter) {
    return request({
        url: api.commentAdd,
        method: 'post',
        data: parameter
    })
}

export function getTopColumn(parameter) {
    return request({
        url: api.topColumnList,
        method: 'get',
        params: parameter
    })
}


export function getSubColumn(parameter) {
    return request({
        url: api.subColumnList,
        method: 'get',
        params: parameter
    })
}