<template>
  <div class="fly-header layui-bg-black">
    <div class="layui-container">
      <a class="fly-logo" href="/">
        <img src="@/assets/logo.png" height="37" alt="layui">
      </a>
      <ul class="layui-nav fly-nav layui-hide-xs">
        <li class="layui-nav-item">
          <!--          <a href="/"><i class="iconfont icon-jiaoliu"></i>案例分析</a>-->
          <a href="/" style="font-size: 18px; font-family: 微软雅黑">致力于培养中国最专业的皮纹分析师</a>
        </li>
        <!--        <li class="layui-nav-item">-->
        <!--          <a href="/doc/index"><i class="iconfont icon-iconmingxinganli"></i>资料下载</a>-->
        <!--        </li>-->
      </ul>

      <ul class="layui-nav fly-nav-user">

        <!-- 未登入的状态 -->
        <!--          <li class="layui-nav-item">-->
        <!--            <a class="iconfont icon-touxiang layui-hide-xs" href="user/login"></a>-->
        <!--          </li>-->
        <!--          <li class="layui-nav-item">-->
        <!--            <a href="user/login">登入</a>-->
        <!--          </li>-->
        <!--          <li class="layui-nav-item">-->
        <!--            <a href="user/reg">注册</a>-->
        <!--          </li>-->
        <!--          <li class="layui-nav-item layui-hide-xs">-->
        <!--            <a href="/app/qq/" onclick="layer.msg('正在通过QQ登入', {icon:16, shade: 0.1, time:0})" title="QQ登入" class="iconfont icon-qq"></a>-->
        <!--          </li>-->
        <!--          <li class="layui-nav-item layui-hide-xs">-->
        <!--            <a href="/app/weibo/" onclick="layer.msg('正在通过微博登入', {icon:16, shade: 0.1, time:0})" title="微博登入" class="iconfont icon-weibo"></a>-->
        <!--          </li>-->

        <!-- 登入后的状态 -->
        <li class="layui-nav-item">
          <a class="fly-nav-avatar" href="javascript:;">
            <cite class="layui-hide-xs">{{ user.name }}</cite>
            <!--            <i class="iconfont icon-renzheng layui-hide-xs" title="认证信息：layui 作者"></i>-->
            <!--            <i class="layui-badge fly-badge-vip layui-hide-xs">VIP3</i>-->
            <img v-if="user.image" :src="user.image.url"/>
            <img v-else src="https://tva1.sinaimg.cn/crop.0.0.118.118.180/5db11ff4gw1e77d3nqrv8j203b03cweg.jpg">
          </a>
          <dl class="layui-nav-child">
            <dd><a href="user/set"><i class="layui-icon">&#xe620;</i>基本设置</a></dd>
            <dd><a href="user/message"><i class="iconfont icon-tongzhi" style="top: 4px;"></i>我的消息</a></dd>
            <dd><a href="user/home"><i class="layui-icon"
                                       style="margin-left: 2px; font-size: 22px;">&#xe68e;</i>我的主页</a></dd>
            <!--            <hr style="margin: 5px 0;">-->
            <!--            <dd><a href="/user/logout/" style="text-align: center;">退出</a></dd>-->
          </dl>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

import {getUserInfo} from "@/assets/api/user";
import {getTopColumn} from "@/assets/api/forum";

export default {
  name: 'Header',
  data() {
    return {
      user: {
        name: ''
      }
    }
  },
  created() {
    this.initPage()
  },
  mounted() {
  },
  methods: {
    initPage() {
      this.initUserInfo();
      this.initTopColumn();
    },
    initUserInfo() {
      console.log('initUserInfo');
      let that = this;
      getUserInfo().then(res => {
        that.user = res.result
      })
    },
    initTopColumn() {
      let that = this;
      getTopColumn().then(res => {

      })
    }

  }
}

</script>

<style scoped>

</style>