<template>
  <div class="fly-footer">
    <p><a href="http://forum.ynatd.com/" target="_blank">皮纹之家</a> 2020 &copy; <a href="http://www.ynatd.com/"
                                                                                  target="_blank">嘉奇教育 出品</a></p>
    <!--      <p>-->
    <!--        <a href="http://fly.layui.com/jie/3147/" target="_blank">付费计划</a>-->
    <!--        <a href="http://www.layui.com/template/fly/" target="_blank">获取Fly社区模版</a>-->
    <!--        <a href="http://fly.layui.com/jie/2461/" target="_blank">微信公众号</a>-->
    <!--      </p>-->
  </div>

  <!--    <script src="../res/layui/layui.js"></script>-->
  <!--    <script>-->
  <!--      layui.cache.page = '';-->
  <!--      layui.cache.user = {-->
  <!--        username: '游客'-->
  <!--        , uid: -1-->
  <!--        , avatar: '../res/images/avatar/00.jpg'-->
  <!--        , experience: 83-->
  <!--        , sex: '男'-->
  <!--      };-->
  <!--      layui.config({-->
  <!--        version: "3.0.0"-->
  <!--        , base: '../res/mods/'  //这里实际使用时，建议改成绝对路径-->
  <!--      }).extend({-->
  <!--        fly: 'index'-->
  <!--      }).use('fly');-->
  <!--    </script>-->
</template>
<script>

export default {
  name: "Footer",
  data() {
    return {}
  },
  created() {
  },
  mounted() {
  },
  methods: {}
}


</script>

<style scoped>

</style>